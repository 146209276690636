



/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 2200px) {
  .ProdImage   {
    width: 30%;
    height: 55vh;
    margin: auto;
    margin-top: 3%;
    display: block;
    margin-left: 20%;
    margin-right: auto;
    object-fit: contain;
    float: left;
    position: relative;
  }
  .prodInfo{
    margin-left: 51%;
    margin-right: auto;
    margin-top: 3%;
    width: 30%
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 700px) {
  .ProdImage   {
    width: 80%;
    height: 55vh;
    margin-top: 3%;
    display: block;
    object-fit: contain;
    display: block;
    margin-left: 10%;
    margin-right: auto;
  }
  .prodInfo{
    margin-left: 5%;
    margin-right: auto;
    text-align: left;
    margin-top: 3%;
    width: 90%
  }
}

.prodCard {
  background-color: white;

}

.ProdName{
    font-size: 20px;
}

.prodDescr {
  margin-top: -1%;
}

.buyIcon{
  margin-top: 30%;
  color: gray;
}

.buyIcon:hover{
  color: gray;
  text-decoration: none;
}

.buyDescr {
  font-weight: bold;
  font-size: 20px;
}
