.womanfheader {
  text-align: center;
  background-color: #a19c9c;
  font-size: 20px;
  font-weight: bold;
  padding: 10;
  height: 6rem;
}



.rowd {
  justify-content: center;
  background-color: red;
}

.columnw {

  background-color: black;
}

.rows {
  float: left;
  width: 100%;
  padding: 2%;
  display: flex;
  justify-content: center;
}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 2200px) {
  .listDiv {
     width: 100%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 700px) {
  .listDiv {
     width: 100%;
     margin-left: 5%;
  }
}
