/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 2200px) {
  .slideImag {
     width: 73%;
     height: 77vh;

  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 700px) {
  .slideImag {
     width: 99%;
     height: 36vh;
  }

}
