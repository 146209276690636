@media screen and (max-width: 1700px) {

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 700px) {

}

.barButton {
  border: 0;
  background-color: #f3f5f7;
}
