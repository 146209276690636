.Footer {
  margin-top: 5px;
}

.upperf {
  background-color: white;
  height: 6rem;
}

.innerf {
  background-color: #8a8a8a;
  height: 3rem;
}







.FooterCol:hover{
    text-decoration: none;
    color: white;
}



.rights {
  background-color: black;
  height: 2rem;
}

.rightInfo {
  color: white;
  text-align: center;
  font-size: 9px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 2200px) {

  .phoneNumb{
    font-size: 1rem;
    font-weight: bold;
    color: white;
    margin-left: 10%;

  }

  .phoneNumb:hover{
      text-decoration: none;
      color: white;
  }

  .cats{
    font-size: 1rem;
    font-weight: bold;
    color: white;
    text-decoration: underline;
        margin-left: 10%;
  }

  .cats:hover{
    text-decoration: none;
    color: white;
  }

  .cath{
    font-size: 1rem;
    font-weight: bold;
    color: white;
    margin-left: 10%;
  }

  .lowercolumn {
    float: left;
    width: 30%;
    margin-top: 3%;
    text-align: left;

  }

  .lowermiddlecol {
    float: left;
    width: 20%;
    margin-top: 3%;
    text-align: left;
  }

  .lowerf {
    background-color: black;
    height: 30rem;
  }

  .Instagram {
    color: white;
  }

  .Instagram:hover {
    color: white;
  }

  .Footie{
    margin-left: 14%;
    height: 33vh;
    width: 65%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 700px) {

  .phoneNumb{
    font-size: 0.6rem;
    font-weight: bold;
    color: white;
    margin-left: 28%;
  }

  .phoneNumb:hover{
      text-decoration: none;
      color: white;
      margin-left: 28%;
  }

  .cats{
    font-size: 1rem;
    font-weight: bold;
    color: white;
    text-decoration: underline;
    text-align: left;
    margin-left: 30%;
  }

  .cats:hover{
    text-decoration: none;
    color: white;
  }

  .cath{
    font-size: 1rem;
    font-weight: bold;
    color: white;
    margin-left: 30%;
  }

  .lowercolumn {
    width: 85%;
    margin: auto;
    margin-top: 3%;
  }

  .lowermiddlecol {
    width: 100%;
    margin-top: 3%;
    text-align: left;
  }

  .lowerf {
    background-color: black;
    height: 70rem;
  }

  .Footie{
    height: 40vh;
    width: 100%;
  }
}
