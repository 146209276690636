.CatHeader {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}



.catImageLeft {

  object-fit: fill;
  width: 20%;
  height: 25rem;
  border: 0.01px solid black;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  float: left;
}

.catImageMiddle {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  object-fit: fill;
  width: 25%;
  height: 25rem;
  border: 0.01px solid black;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  float: left;
}


@media screen and (max-width: 2200px) {
  .SalonImage {
     width: 70%;
     height: 50vh;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 700px) {
  .SalonImage {
     width: 99%;
     height: 25vh;
  }

}
