.ThirdHw {
  text-align: center;
  margin-top: 3%;
  color: black;
  font-size: 25px;
  font-weight: bold;
  padding: 20px 0;
  text-align: center;
}

.inner {
  top: 50%;
}

.ThirdHeader {
  height: 5.5rem;
  background-color:#ffffff;
}
