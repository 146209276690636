html,body
{
    overflow-x: hidden;
}

.Navbar {
  height: 6rem;
  width: 100%;
  background-color: white;
}


.info {
  right: 0
}

.slick-slide img {
  margin: auto;
}


.nav {
  border-bottom: 1px solid;

}
.sidebar-subitem-text {
  font-size: 0.8rem;
}

.Footer {
  clear: both;
  position: relative;
  height: 200px;
  margin-top: -200px;
}
