


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 2200px) {
  .Logo {
    width: 5rem;
    height: 4.5rem;
  }

  .logoDiv{
    float: left;
    width: 50%;
    margin-top: 1%;
    margin-left: 3%;

  }

  .contacts {
    width: 10%;
    margin: auto;
    color: white;
    height: 1rem;
  }

  .Instagram {
    margin-top: 15%;
    margin: auto;
    color: white;
  }

  .Instagram:hover {
    margin-top: 15%;
    margin: auto;
    color: white;
  }

  .colcol {
    margin-left: 40%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 700px) {
  .Logo {
    width: 5rem;
    height: 4.5rem;
  }

  .logoDiv{
    float: left;
    width: 30%;
    margin-top: 4.5%;
    margin-left: 5%;

  }

  .Instagram {
    margin-top: 15%;
    margin: auto;
    color: white;
  }

  .Instagram:hover {
    margin-top: 15%;
    margin: auto;
    color: white;
  }

  .searchBar {
    width: 30%;
    background-color: blue;
    height: 5rem;
    float: left;
  }


  .colcol {
    margin-left: 30%;
  }

  .contacts {
    width: 35%;
  }

}
