.Card {
  background-color: white;
  width: 15rem;
  height: 18rem;
  padding: 10;
  border: 0.1px solid;
  border-color: grey;
  margin-top: 5%;
  font-family: arial;
  margin: 2px;
}

.Card:hover {
  background-color: white;
  width: 15rem;
  height: 18rem;
  padding: 10;
  border: 0.2px solid;
  border-color: black;
  margin: 2px;
  transform: scale(1.05);
}

.CardBig {
  background-color: white;
  width: 15rem;
  height: 22rem;
  padding: 10;
  border: 1.5px dotted;
  border-color:  #4f88e3;
  margin-top: 5%;
  font-family: arial;
  margin: 2px;
}

.CardBig:hover {
  background-color: white;
  width: 15rem;
  height: 22rem;
  padding: 10;
  border: 1.5px dotted;
  border-color: #4f88e3;
  margin: 2px;
  transform: scale(1.05);
}

.CardMedium {
  background-color: white;
  width: 15rem;
  height: 20rem;
  padding: 10;
  border: 0.1px solid;
  border-color: grey;
  margin-top: 5%;
  font-family: arial;
  margin: 2px;
}

.CardMedium:hover {
  background-color: white;
  width: 15rem;
  height: 20rem;
  padding: 10;
  border: 0.2px solid;
  border-color: black;
  margin: 2px;
  transform: scale(1.05);
}


.prodtype{
  color: black;
  font-size: 11px;
  margin-left: 2%;
  text-align: center;

}

.name {
  color: black;
  font-size: 15px;
  font-weight: bold;
  margin-left: 2%;
}

.descr {
  color: black;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin-left: 2%;
}

.price {
  color: black;
  font-size: 15px;
  font-weight: bold;
  margin-left: 2%;
}

.cardImage {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  object-fit: contain;
  width: 14rem;
  height: 14rem;
}

.ProductCard:hover {
  text-decoration: none;
}
