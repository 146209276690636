.womanfheader {
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  
}

.productcard {
  margin-top: 5%;
}
